import React from "react";
// react component for creating dynamic tables
import ReactTable, { usePagination } from "react-table";

import Iframe from "react-iframe";

import { useReactToPrint } from "react-to-print";

// @material-ui/core components
import {
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Table,
  makeStyles,
  CircularProgress,
  LinearProgress,
  Slide,
  Dialog,
  DialogContent,
  TablePagination,
  Tooltip,
  IconButton,
  Badge,
  withStyles,
  Typography,
  SwipeableDrawer,
  Chip,
} from "@material-ui/core";
// @material-ui/icons
import {
  LocalOffer,
  Visibility,
  Send,
  Edit,
  EditOutlined,
  Refresh,
  GetApp,
} from "@material-ui/icons";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import CardHeader from "components/Card/CardHeader.js";
import axios from "axios";
import RefreshIcon from "@material-ui/icons/Refresh";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import dateFormat from "dateformat";
import { Animated } from "react-animated-css";
import jwt from "jsonwebtoken";
import FileTags from "./FileTags";
import FileTasks from "./FileTasks";
import FileAdvanceView from "../AdvanceView/FileAdvanceView";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import CallReceivedIcon from "@material-ui/icons/CallReceived";
import FiberNewIcon from "@material-ui/icons/FiberNew";
import RateReview from "@material-ui/icons/RateReview";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import Filters from "./Filters";
import {
  addZeroes,
  currentTracking,
  formatDate,
  formatDateTime,
} from "views/LDocs/Functions/Functions";
import {
  Menu,
  Item,
  Separator,
  animation,
  MenuProvider,
  theme,
  Submenu,
} from "react-contexify";
import "react-contexify/dist/ReactContexify.min.css";
import Swal from "sweetalert2";
import {
  successAlert,
  errorAlert,
  msgAlert,
} from "views/LDocs/Functions/Functions";
import styles2 from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import InitWorkflow from "../InitWorkflow/InitWorkflow";
import Pending_Invoice from "assets/img/statuses/Asset_1.png";
import Recieved_Invoice from "assets/img/statuses/Asset_4.png";
import UnderReview_Invoice from "assets/img/statuses/Asset_3.png";
import Approved_Invoice from "assets/img/statuses/Asset_5.png";
import Rejected_Invoice from "assets/img/statuses/Asset_6.png";
import Resubmit_Invoice from "assets/img/statuses/Asset_7.png";
import Ready_Invoice from "assets/img/statuses/Asset_2.png";
import Pending from "assets/img/statuses/Pending.png";
import Success from "assets/img/statuses/Success.png";
import Rejected from "assets/img/statuses/Rejected.png";
import NoStatus from "assets/img/statuses/NoStatus.png";
import VerticalLinearStepper from "../../../Components/VerticalStepper";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import { useDispatch, useSelector } from "react-redux";
import FileReceived from "./FileReceived";
import InitiatePayment from "./InitiatePayment";
import { partial } from "lodash";
import CreateInvoice from "../CreateInvoice/CreateInvoice";
import ExportToFusion from "./ExportToFusion";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { setIsTokenExpired } from "actions";
import { filterInvoice } from "actions";
import { useParams } from "react-router-dom";
import InfoIcon from "@material-ui/icons/Info";

//Filters
const filters = [
  { id: "unread", value: "Pending for Acceptance", id: 1 },
  { id: "read", value: "Accepted (Initially Reviewed)", id: 2 },
  { id: "rejected", value: "Pending for Review", id: 3 },
  { id: "pending", value: "Reviewed", id: 4 },
  { id: "reviewed", value: "Pending For Approval", id: 5 },
  { id: "rejected", value: "Approved", id: 6 },
  { id: "pending", value: "Correction Required", id: 7 },
  { id: "approved", value: "Rejected ", id: 8 },
];

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  cardTitleText: {
    color: "white",
  },
  table: {
    minWidth: "100%",
    border: 1,
  },
  TableCell: {
    minWidth: "10%",
  },
  TableRow: {
    cursor: "pointer",
    background: "white",
    border: 1,
    width: "100%",
  },
  TableID: {
    maxWidth: "3%",
  },
};

const useStyles = makeStyles(styles);
const sweetAlertStyle = makeStyles(styles2);
const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -7,
    top: -2,
    border: `2px solid #095392`,
    padding: "0 4px",
    background: "#095392",
  },
}))(Badge);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const TransitionRight = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});
const TransitionLeft = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function FilesList(props) {
  const Token =
    useSelector((state) => state.userReducer.Token) ||
    localStorage.getItem("cooljwt");
  const { filter } = useSelector((state) => state.filter);
  const dispatch = useDispatch();
  let userDetail = jwt.decode(localStorage.getItem("cooljwt"));
  let isVendor = userDetail.isVendor;
  const { isFilter } = useParams();
  const classes = useStyles();
  const [componentName, setComponentName] = React.useState("Invoices");
  const [classicModal, setClassicModal] = React.useState(false);
  const [tagModal, setTagModal] = React.useState(false);
  const [qrModal, setQrModal] = React.useState(false);
  const [taskModal, setTaskModal] = React.useState(false);
  const [reviewerModal, setReviewerModal] = React.useState(false);
  const [initWorkFlowModal, setInitWorkFlowModal] = React.useState(false);
  const [animateTable, setAnimateTable] = React.useState(true);
  const [animatePdf, setAnimatePdf] = React.useState(false);
  const [animateBlockChain, setAnimateBlockChain] = React.useState(false);
  const [animateQr, setAnimateQr] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isViewing, setIsViewing] = React.useState(false);
  const [isViewingBlockChainView, setIsViewingBlockChainView] = React.useState(
    false
  );
  const [pdfUrl, setPdfUrl] = React.useState(false);
  const [pdfModalData, setPdfModalData] = React.useState(false);
  const [data, setData] = React.useState();
  const [row, setRow] = React.useState();
  const [blockChainData, setBlockChainData] = React.useState(null);
  const [markAsReceivedModel, setMarkAsReceivedModel] = React.useState(false);
  const [initPaymentModel, setinitPaymentModel] = React.useState(false);
  const [exportToFusionModel, setExportToFusionModel] = React.useState(false);
  const [editInvoice, setEditInvoiceModel] = React.useState(false);
  const [draftInvoice, setDratInvoice] = React.useState(false);
  const [decoded, setDecoded] = React.useState(null);
  const [view, setView] = React.useState("read");
  const [showFiltersModel, setShowFiltersModel] = React.useState(false);
  const [organizationalId, setOrganizationId] = React.useState("");
  const [filesData, setFilesData] = React.useState([]);
  const [editHandler, setEdithandler] = React.useState(null);
  const [openTrackingModal, setOpenTrackingModal] = React.useState(false);
  const [trackingLoading, setTrackingLoading] = React.useState(false);
  const [initialReview, setInitialReview] = React.useState("");
  const [review, setReview] = React.useState("");
  const [approve, setApprove] = React.useState("");
  const [approveandexported, setApproveAndExported] = React.useState("");
  const [canceled, setCanceled] = React.useState("");
  const [showFwdDetails, setShowFwdDetails] = React.useState(false);
  const [showFwdData, setShowFwdData] = React.useState(null);
  const [totalCount, setTotalCount] = React.useState("");
  const [formState, setFormState] = React.useState({
    filters: {
      invoiceId: true,
      status: true,
      date: true,
      amount: true,
      partialPaid: true,
      fullPaid: true,
      notPaid: true,
      invoiceType: true,
    },
    values: {
      invoiceId: "",
      status: [],
      submitStart: null,
      submitEnd: null,
      amountTo: null,
      amountfrom: null,
      partialPaid: null,
      fullPaid: null,
      notPaid: null,
      invoiceType: null,
      supplier: null,
      po: null,
    },
  });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  //Add File Tags
  const addFileTags = (row) => {
    setRow(row);
    setTagModal(true);
  };
  //Mark as Received
  const markAsReceived = (row) => {
    setRow(row);
    setMarkAsReceivedModel(true);
  };
  //Payment as Received
  const initPayment = (row) => {
    setRow(row);
    setinitPaymentModel(true);
  };
  const exportToFusion = (row) => {
    setRow(row);
    setExportToFusionModel(true);
  };
  //Edit Invoice
  const editSelectedInvoice = (row, i) => {
    let status =
      row?.trackingStatus?.received?.status == "inProgress" ? true : false;
    //if i = 1 ? Edit
    //if i = 2 ? Resubmission
    setEdithandler(i);
    setRow(row);
    setDratInvoice(status);
    setAnimateTable(false);
    setEditInvoiceModel(true);
  };
  //Add File Tasks
  const addFileTasks = (row) => {
    setRow(row);
    setTaskModal(true);
  };
  //View File
  const viewFile = (row) => {
    setIsViewing(false);
    setPdfModalData(row);
    setPdfUrl(
      `${process.env.REACT_APP_LDOCS_API_URL}/${row.invoicePath}/${row.invoiceId}.pdf`
      // '${process.env.REACT_APP_LDOCS_API_URL}/Tenants/19881545-1519-4a39-a8c6-9442e81098d1/60097b478057a239384abad5/601a8a3b65c1c4271439d998/101-ORGONE/1/101-ORGONE.pdf'
    );
    setIsViewing(true);
    setAnimateTable(false);
    setAnimatePdf(true);
  };
  //Open BlockChainView
  const viewBlockChainView = (row) => {
    setBlockChainData(null);
    setAnimateBlockChain(true);
    setAnimateTable(false);
    setIsViewingBlockChainView(true);
    axios({
      method: "get", //you can set what request you want to be
      url: `${process.env.REACT_APP_LDOCS_API_BOOKCHAIN_URL}/api/invoiceWorkflow/get-invoice-workflow-history/${row.vendorId}-${row.invoiceId}-${row.version}`,
    }).then((response) => {
      if (response.data.InvoiceWorkflowHistory.length !== 0) {
        let blockChainData = response.data.InvoiceWorkflowHistory;
        setBlockChainData(blockChainData);
        setIsViewingBlockChainView(true);
        setAnimateTable(false);
        setAnimateBlockChain(true);
      }
    });
  };
  //Open Advance View
  const viewQrView = (row) => {
    setRow(row);
    setQrModal(true);
    setAnimateTable(false);
    setAnimateQr(true);
  };

  const pageChange = (page) => {
    console.log(page);
  };

  //Close Views
  const goBack = () => {
    setPdfUrl();
    setQrModal(false);
    setIsViewing(false);
    setIsViewingBlockChainView(false);
    getMyFiles(userDetail, false);
    setAnimateTable(true);
    setAnimatePdf(false);
    setAnimateBlockChain(false);
    setAnimateQr(false);
    setPdfModalData("");
  };
  //Use Effect Hook
  React.useEffect(() => {
    let userDetail = jwt.decode(localStorage.getItem("cooljwt"));
    setDecoded(userDetail);
    if (!isFilter) {
      getMyFiles(userDetail, true);
    }
  }, [page, rowsPerPage, formState?.values]);

  React.useEffect(() => {
    if (
      formState?.values?.status?.length === 0 &&
      formState?.values?.invoiceId === "" &&
      formState?.values?.amountfrom === null &&
      formState?.values?.amountTo === null &&
      formState?.values?.fullPaid === null &&
      formState?.values?.invoiceType === null &&
      formState?.values?.notPaid === null &&
      formState?.values?.partialPaid === null &&
      formState?.values?.submitEnd === null &&
      formState?.values?.submitStart === null &&
      formState?.values?.supplier === null &&
      formState?.values?.po === null
    ) {
      getFilterData(userDetail, true);
    }
  }, [page, rowsPerPage, formState?.values]);

  // React.useEffect(() => {
  //   let userDetail = jwt.decode(localStorage.getItem('cooljwt'))
  //   setDecoded(userDetail)
  //     getMyFiles(userDetail, true)
  // }, [formState?.values])

  const _trackingStatus = (trackingStatus) => {
    let userDetail = jwt.decode(localStorage.getItem("cooljwt"));
    let isVendor = userDetail?.isVendor;
    if (isVendor) {
      return;
    }
    if (trackingStatus) {
      setOpenTrackingModal(true);
      // setTrackingLoading(true)
      setInitialReview(trackingStatus?.initialReview);
      setReview(trackingStatus?.underReview);
      setApprove(trackingStatus?.underApprove);
      setApproveAndExported(trackingStatus?.paymentInProcess);
      setCanceled(trackingStatus?.paid);
    } else {
      setOpenTrackingModal(false);
      setTrackingLoading(false);
      msgAlert("Tracking Status not Found");
    }
  };
  const closeTrackingModal = () => {
    setOpenTrackingModal(false);
    setInitialReview("");
    setReview("");
    setApprove("");
    setApproveAndExported("");
    setCanceled("");
  };

  const setTableData = (response) => {
    let userDetail = jwt.decode(localStorage.getItem("cooljwt"));
    let isVendor = userDetail.isVendor;

    setData(
      response.map((prop, key) => {
        let status =
          prop?.trackingStatus[prop?.trackingStatus?.current_status]?.status;
        let exportStatus = prop?.trackingStatus?.current_status;
        var currentStatus = currentTracking(prop?.trackingStatus);
        let isSubmitedByVendor =
          !prop?.initWorkFLow && !isVendor && prop?.markedAs == "unread";
        let isCorrectionRequiredInWorkflow =
          !isVendor && prop?.workFlowStatus == "correctionRequired";
        return {
          id: prop?._id,
          invoiceId: prop?.originalInvoiceId || "",
          status: (
            <MenuProvider
              style={{ paddingTop: 40, paddingBottom: 40 }}
              data={prop}
              id="menu_id"
            >
              {/* if Inoice is Draft */}

              {prop?.trackingStatus?.received?.status == "inProgress" ? (
                <Tooltip title="INCOMPLETE">
                  <Chip
                    variant="outlined"
                    size="small"
                    // avatar={<Avatar>M</Avatar>}
                    label="INCOMPLETE"
                    clickable
                    onClick={() => _trackingStatus(prop?.trackingStatus)}
                    style={{ border: "orange 1px solid", color: "orange" }}
                  />
                </Tooltip>
              ) : // if Inoice is Not Draft
              prop?.trackingStatus?.paid?.status == "partial" ? (
                <Tooltip title="PARTIALLY PAID">
                  <Chip
                    variant="outlined"
                    size="small"
                    // avatar={<Avatar>M</Avatar>}
                    label="PARTIALLY PAID"
                    clickable
                    onClick={() => _trackingStatus(prop?.trackingStatus)}
                    style={{
                      border: "lightgreen 1px solid",
                      color: "lightgreen",
                    }}
                  />
                </Tooltip>
              ) : prop?.trackingStatus?.paid?.status == "completed" ? (
                <Tooltip title="FULLY PAID">
                  <Chip
                    variant="outlined"
                    size="small"
                    // avatar={<Avatar>M</Avatar>}
                    label="FULLY PAID"
                    clickable
                    onClick={() => _trackingStatus(prop?.trackingStatus)}
                    style={{ border: "green 1px solid", color: "green" }}
                  />
                </Tooltip>
              ) : prop?.trackingStatus?.paid?.status == "Canceled" ? (
                <Tooltip title="CANCELED">
                  <Chip
                    variant="outlined"
                    size="small"
                    // avatar={<Avatar>M</Avatar>}
                    label="CANCELED"
                    clickable
                    onClick={() => _trackingStatus(prop?.trackingStatus)}
                    color="secondary"
                  />
                </Tooltip>
              ) : currentStatus.status == "readyToPay" ? (
                <Tooltip title="READY TO PAY">
                  <Chip
                    variant="outlined"
                    size="small"
                    // avatar={<Avatar>M</Avatar>}
                    label="READY TO PAY"
                    clickable
                    onClick={() => _trackingStatus(prop?.trackingStatus)}
                    style={{ border: "orange 1px solid", color: "orange" }}
                  />
                </Tooltip>
              ) : prop?.trackingStatus?.paymentInProcess?.status ==
                "completed" ? (
                <Tooltip title="APPROVED AND EXPORTED">
                  <Chip
                    variant="outlined"
                    size="small"
                    // avatar={<Avatar>M</Avatar>}
                    label="APPROVED AND EXPORTED"
                    clickable
                    onClick={() => _trackingStatus(prop?.trackingStatus)}
                    style={{ border: "green 1px solid", color: "green" }}
                  />
                </Tooltip>
              ) : prop?.workFlowStatus == "correctionRequired" &&
                prop?.markedAs !== "rejected" ? (
                <Tooltip title="Correction Required">
                  <Chip
                    variant="outlined"
                    size="small"
                    // avatar={<Avatar>M</Avatar>}
                    label="CORRECTION REQUIRED"
                    clickable
                    onClick={() => _trackingStatus(prop?.trackingStatus)}
                    style={{ border: "orange 1px solid", color: "orange" }}
                  />
                </Tooltip>
              ) : prop?.trackingStatus?.underApprove?.status == "inProgress" ? (
                <Tooltip title="PENDING">
                  <Chip
                    variant="outlined"
                    size="small"
                    // avatar={<Avatar>M</Avatar>}
                    label="PENDING"
                    clickable
                    onClick={() => _trackingStatus(prop?.trackingStatus)}
                    color="primary"
                  />
                </Tooltip>
              ) : prop?.trackingStatus?.underApprove?.status == "completed" &&
                prop?.trackingStatus?.paymentInProcess?.status !==
                  "completed" ? (
                <Tooltip title="APPROVED">
                  <Chip
                    variant="outlined"
                    size="small"
                    // avatar={<Avatar>M</Avatar>}
                    label="APPROVED"
                    clickable
                    onClick={() => _trackingStatus(prop?.trackingStatus)}
                    style={{ border: "green 1px solid", color: "green" }}
                  />
                </Tooltip>
              ) : prop?.trackingStatus?.underReview?.status == "inProgress" ? (
                <Tooltip title="PENDING">
                  <Chip
                    variant="outlined"
                    size="small"
                    // avatar={<Avatar>M</Avatar>}
                    label="PENDING"
                    clickable
                    onClick={() => _trackingStatus(prop?.trackingStatus)}
                    color="primary"
                  />
                </Tooltip>
              ) : prop?.trackingStatus?.initialReview?.status ==
                "inProgress" ? (
                <Tooltip title="PENDING">
                  <Chip
                    variant="outlined"
                    size="small"
                    // avatar={<Avatar>M</Avatar>}
                    label="PENDING"
                    clickable
                    onClick={() => _trackingStatus(prop?.trackingStatus)}
                    color="primary"
                  />
                </Tooltip>
              ) : currentStatus?.status == "rejected" ? (
                <Tooltip title="REJECTED">
                  <Chip
                    variant="outlined"
                    size="small"
                    // avatar={<Avatar>M</Avatar>}
                    label="REJECTED"
                    clickable
                    onClick={() => _trackingStatus(prop?.trackingStatus)}
                    color="secondary"
                  />
                </Tooltip>
              ) : currentStatus?.status == "Canceled" ? (
                <Tooltip title="Canceled">
                  <Chip
                    variant="outlined"
                    size="small"
                    // avatar={<Avatar>M</Avatar>}
                    label="Canceled"
                    clickable
                    onClick={() => _trackingStatus(prop?.trackingStatus)}
                    color="secondary"
                  />
                </Tooltip>
              ) : (currentStatus?.status == "correctionRequired" &&
                  currentStatus?.val == 1) ||
                isCorrectionRequiredInWorkflow ? (
                <Tooltip title="SENT FOR CORRECTION">
                  <Chip
                    variant="outlined"
                    size="small"
                    // avatar={<Avatar>M</Avatar>}
                    label="SENT FOR CORRECTION"
                    onClick={() => _trackingStatus(prop?.trackingStatus)}
                    clickable
                    style={{ border: "orange 1px solid", color: "orange" }}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="PENDING">
                  <Chip
                    variant="outlined"
                    size="small"
                    // avatar={<Avatar>M</Avatar>}
                    label="PENDING"
                    clickable
                    onClick={() => _trackingStatus(prop?.trackingStatus)}
                    color="primary"
                  />
                </Tooltip>
              )
              /* ) : prop?.markedAs == "unread" ? (
                    <Chip
                      style={{ background: "#deb725", color: "#fff" }}
                      label="Pending"
                    />
                  ) : prop?.markedAs == "read" ? (
                    <Chip label="Received" color="primary" />
                  ) : prop?.markedAs == "rejected" ? (
                    <Chip color="secondary" label="Rejected" />
                  ) : (
                    ""
                  )} */
              }
            </MenuProvider>
          ),
          po: prop?.po,
          invoiceType: prop?.isPrePayment
            ? "Pre-Payment"
            : prop?.isPettyCash
            ? "Petty Cash"
            : prop?.isReceipt
            ? "With Receipt"
            : "",
          createdDate: (
            <MenuProvider
              style={{ paddingTop: 40, paddingBottom: 40 }}
              data={prop}
              id="menu_id"
            >
              {formatDateTime(prop?.createdDate)}
            </MenuProvider>
          ),
          invoiceDate: (
            <MenuProvider
              style={{ paddingTop: 40, paddingBottom: 40 }}
              data={prop}
              id="menu_id"
            >
              {formatDate(prop?.invoiceDate)}
            </MenuProvider>
          ),
          date: (
            <MenuProvider
              style={{ paddingTop: 40, paddingBottom: 40 }}
              data={prop}
              id="menu_id"
            >
              {formatDate(prop?.dueDate)}
            </MenuProvider>
          ),
          vendorName: (
            <MenuProvider
              style={{ paddingTop: 40, paddingBottom: 40 }}
              data={prop}
              id="menu_id"
            >
              {prop?.vendorName}
            </MenuProvider>
          ),
          customerName: (
            <MenuProvider
              style={{ paddingTop: 40, paddingBottom: 40 }}
              data={prop}
              id="menu_id"
            >
              {prop?.organizationName}
            </MenuProvider>
          ),
          netAmt: (
            <MenuProvider
              style={{ paddingTop: 40, paddingBottom: 40 }}
              data={prop}
              id="menu_id"
            >
              <Tooltip
                title={`${prop?.FC_currency?.Code} 1 ≈ ${
                  prop?.LC_currency?.Code
                } ${
                  prop?.PO_ConversionRate
                    ? parseFloat(prop?.PO_ConversionRate).toFixed(4)
                    : ""
                }`}
                aria-label="conversionRate"
              >
                <div>
                  {`${prop?.FC_currency?.Code} ${addZeroes(prop?.netAmt)}`}
                  <br />
                  {prop?.FC_currency && prop?.LC_currency
                    ? prop?.FC_currency._id !== prop?.LC_currency?._id
                      ? `(${prop?.LC_currency?.Code || ""} ${addZeroes(
                          prop?.netAmt_bc
                        ) || "0.00"})`
                      : ""
                    : ""}
                </div>
              </Tooltip>
            </MenuProvider>
          ),
          version: (
            <MenuProvider
              style={{ paddingTop: 40, paddingBottom: 40 }}
              data={prop}
              id="menu_id"
            >
              {prop?.version}
            </MenuProvider>
          ),
          reviewed: (
            <MenuProvider
              style={{ paddingTop: 40, paddingBottom: 40 }}
              data={prop}
              id="menu_id"
            >
              {prop?.reviewStatus == "pending" ? (
                <div className="fileinput text-center">
                  <div className="thumbnail img-circle2">
                    <img src={Pending} alt={prop?.reviewStatus} />
                  </div>
                </div>
              ) : prop?.reviewStatus == "reviewed" ? (
                <div className="fileinput text-center">
                  <div className="thumbnail img-circle2">
                    <img src={Success} alt={prop?.reviewStatus} />
                  </div>
                </div>
              ) : prop?.reviewStatus == "rejected" ? (
                <div className="fileinput text-center">
                  <div className="thumbnail img-circle2">
                    <img src={Rejected} alt={prop?.reviewStatus} />
                  </div>
                </div>
              ) : (
                <div className="fileinput text-center">
                  <div className="thumbnail img-circle2">
                    <img src={NoStatus} alt={prop?.reviewedStatus} />
                  </div>
                </div>
              )}
            </MenuProvider>
          ),
          approved: (
            <MenuProvider
              style={{ paddingTop: 40, paddingBottom: 40 }}
              data={prop}
              id="menu_id"
            >
              {prop?.approveStatus == "pending" ? (
                <div className="fileinput text-center">
                  <div className="thumbnail img-circle2">
                    <img src={Pending} alt={prop?.approvedstatus} />
                  </div>
                </div>
              ) : prop?.approveStatus == "approved" ? (
                <div className="fileinput text-center">
                  <div className="thumbnail img-circle2">
                    <img src={Success} alt={prop?.approvedstatus} />
                  </div>
                </div>
              ) : prop?.approveStatus == "rejected" ? (
                <div className="fileinput text-center">
                  <div className="thumbnail img-circle2">
                    <img src={Rejected} alt={prop?.approvedstatus} />
                  </div>
                </div>
              ) : (
                <div className="fileinput text-center">
                  <div className="thumbnail img-circle2">
                    <img src={NoStatus} alt={prop?.approvedstatus} />
                  </div>
                </div>
              )}
            </MenuProvider>
          ),
          actions: (
            <div className="actions-right">
              <Tooltip title="View File" aria-label="viewfile">
                <Button
                  justIcon
                  round
                  simple
                  icon={Visibility}
                  onClick={() => {
                    viewFile(prop);
                  }}
                  color="danger"
                  className="Edit"
                >
                  <Visibility />
                </Button>
              </Tooltip>
              <Tooltip title="Add Task" aria-label="addtask">
                <Button
                  justIcon
                  round
                  simple
                  icon={PlaylistAddIcon}
                  onClick={() => {
                    addFileTasks(prop);
                  }}
                  color="info"
                  className="Edit"
                >
                  <PlaylistAddIcon />
                </Button>
              </Tooltip>
              <Tooltip title="Add Tags" aria-label="addtags">
                <Button
                  justIcon
                  round
                  simple
                  icon={LocalOffer}
                  onClick={() => {
                    addFileTags(prop);
                  }}
                  color="info"
                  className="Edit"
                >
                  <LocalOffer />
                </Button>
              </Tooltip>
              {prop?.initWorkFlow && !isVendor ? (
                <Tooltip title="BlockChain View" aria-label="blockChainView">
                  <Button
                    justIcon
                    round
                    simple
                    icon={ClearAllIcon}
                    onClick={() => {
                      viewBlockChainView(prop);
                    }}
                    color="info"
                    className="Edit"
                  >
                    <ClearAllIcon />
                  </Button>
                </Tooltip>
              ) : (
                ""
              )}
              {!isVendor ? (
                <>
                  <Tooltip title="EXCLE FILE" aria-label="export">
                    <Button
                      justIcon
                      round
                      simple
                      icon={GetApp}
                      onClick={() => {
                        exportToCSV(prop);
                      }}
                      color="info"
                      className="Edit"
                    >
                      <GetApp />
                    </Button>
                  </Tooltip>
                  {userDetail?.tenantConfigs?.autoInitWorkFlow == false ? (
                    <Tooltip title="Init Workflow" aria-label="initWorkflow">
                      <Button
                        justIcon
                        round
                        simple
                        icon={<Send />}
                        onClick={() => {
                          initWorkFLowAction(prop);
                        }}
                        color="info"
                        className="Edit"
                      >
                        <Send />
                      </Button>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
              <Tooltip title="360&#176; View" aria-label="advanceDocumentView">
                <Button
                  justIcon
                  round
                  simple
                  icon={ViewModuleIcon}
                  onClick={() => {
                    viewQrView(prop);
                  }}
                  color="info"
                  className="Edit"
                >
                  <ViewModuleIcon />
                </Button>
              </Tooltip>
              {/* {isCorrectionRequiredInWorkflow && prop.createdByVendor ? (
                <Tooltip
                  title={
                    isSubmitedByVendor
                      ? "Mark as Received"
                      : isCorrectionRequiredInWorkflow
                      ? "Send for Correction"
                      : "Mark as Received"
                  }
                  aria-label="received"
                >
                  <Button
                    justIcon
                    round
                    simple
                    icon={RateReview}
                    onClick={() => markAsReceived(prop)}
                    color="info"
                  >
                    <RateReview />
                  </Button>
                </Tooltip>
              ) : (
                ""row.workFlowStatus[row.workFlowStatus.status]
                                  ?.approvedBy
              )} */}

              {prop?.createdByVendor &&
              prop?.trackingStatus?.received?.status == "inProgress" ? (
                ""
              ) : prop?.workFlowStatus !== "correctionRequired" &&
                currentStatus?.status != "Canceled" &&
                currentStatus?.status != "rejected" &&
                prop?.trackingStatus?.paymentInProcess?.status !==
                  "completed" &&
                !isVendor ? (
                prop?.trackingStatus?.received?.status !== "inProgress" &&
                status === "inProgress" ? (
                  ""
                ) : (
                  <Tooltip title={"Edit Invoice"} aria-label="received">
                    <Button
                      justIcon
                      round
                      simple
                      icon={EditOutlined}
                      onClick={() => editSelectedInvoice(prop, 1)}
                      color="info"
                    >
                      <EditOutlined />
                    </Button>
                  </Tooltip>
                )
              ) : (
                ""
              )}
              {isVendor &&
              prop?.createdByVendor &&
              prop?.trackingStatus?.received?.status == "inProgress" ? (
                <Tooltip title={"Edit Invoice"} aria-label="received">
                  <Button
                    justIcon
                    round
                    simple
                    icon={EditOutlined}
                    onClick={() => editSelectedInvoice(prop, 1)}
                    color="info"
                  >
                    <EditOutlined />
                  </Button>
                </Tooltip>
              ) : (
                ""
              )}

              {prop?.workFlowStatus == "correctionRequired" &&
              // prop.createdByVendor &&
              !isVendor ? (
                <Tooltip title={"Re Submit Invoice"} aria-label="received">
                  <Button
                    justIcon
                    round
                    simple
                    icon={EditOutlined}
                    onClick={() => editSelectedInvoice(prop, 2)}
                    color="info"
                  >
                    <EditOutlined />
                  </Button>
                </Tooltip>
              ) : (
                ""
              )}

              {prop?.markedAs !== "rejected" &&
              prop?.workFlowStatus == "correctionRequired" &&
              !isVendor ? (
                // && !prop.createdByVendor &&
                <React.Fragment>
                  {/* <Tooltip title="RE SUBMIT" >
                  <Button
                    justIcon
                    round
                    simple
                    icon={RefreshIcon}
                    onClick={() => editSelectedInvoice(prop, 2)}
                    color="info"
                  >
                    <RefreshIcon />
                  </Button>
                </Tooltip> */}
                  {/* <Tooltip title={"Edit Invoice"} aria-label="received">
                    <Button
                      justIcon
                      round
                      simple
                      icon={EditOutlined}
                      onClick={() => editSelectedInvoice(prop, 1)}
                      color="info"
                    >
                      <EditOutlined />
                    </Button>
                  </Tooltip> */}
                </React.Fragment>
              ) : (
                ""
              )}
              {currentStatus?.val == 1 &&
              currentStatus?.status == "correctionRequired" &&
              // && !prop.createdByVendor
              isVendor ? (
                <Tooltip title="RE SUBMIT">
                  <Button
                    justIcon
                    round
                    simple
                    icon={RefreshIcon}
                    onClick={() => editSelectedInvoice(prop, 2)}
                    color="info"
                  >
                    <RefreshIcon />
                  </Button>
                </Tooltip>
              ) : (
                ""
              )}

              {/* {prop.approveStatus == "approved" && !isVendor ? (
                <React.Fragment>
                  <Tooltip title="Initiate Payment" aria-label="initPayment">
                    <Button
                      justIcon
                      round
                      simple
                      icon={MonetizationOnIcon}
                      onClick={() => initPayment(prop)}
                      color="info"
                    >
                      <MonetizationOnIcon />
                    </Button>
                  </Tooltip> */}
              {/* {!prop.exported && !isVendor ? (
                    <Tooltip title="Export to Fusion" aria-label="export">
                      <Button
                        justIcon
                        round
                        simple
                        icon={ExitToAppIcon}
                        onClick={() => exportToFusion(prop)}
                        color="info"
                      >
                        <ExitToAppIcon />
                      </Button>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                 </React.Fragment>
              ) : (
                ""
              )} */}
            </div>
          ),
        };
      })
    );
  };
  const exportToCSV = (file) => {
    axios({
      method: "post", //you can set what request you want to be
      url:
        file == undefined
          ? `${process.env.REACT_APP_LDOCS_API_URL}/report/ExportToCsv`
          : `${process.env.REACT_APP_LDOCS_API_URL}/report/InvoiceToXlsx`,
      data:
        file == undefined
          ? { organizationId: userDetail?.orgDetail?.organizationId }
          : {
              tenantId: file.tenantId,
              organizationId: file.organizationId,
              invoiceId: file.invoiceId,
              version: file.version,
            },
      headers: {
        cooljwt: Token,
        // responseType: 'blob',
      },
    })
      .then((response) => {
        const downloadUrl = `${process.env.REACT_APP_LDOCS_API_URL}/${response.data.path}`;
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", ""); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //Get Files
  // let postData =  {
  //   pagination :  user.isVendor || user.isTenant ? '30' : null,
  //   page: user.isVendor || user.isTenant ? '1' : null
  // }
  const getMyFiles = async (user, loading) => {
    setIsLoading(loading);
    axios({
      method: userDetail?.isTenant ? "get" : "post", //you can set what request you want to be
      url: userDetail?.isVendor
        ? `${process.env.REACT_APP_LDOCS_API_URL}/invoice/getInvoiceByVendor?page=${page}&items=${rowsPerPage}`
        : userDetail?.isTenant
        ? `${process.env.REACT_APP_LDOCS_API_URL}/invoice/getInvoiceTenant/${user.tenantId}`
        : `${process.env.REACT_APP_LDOCS_API_URL}/invoice/getInvoiceOrg/${userDetail?.orgDetail?.organizationId}?page=${page}&items=${rowsPerPage}`,
      // data:  userDetail?.isVendor || userDetail?.isTenant ? null : formState?.values ,
      data: userDetail?.isVendor
        ? formState?.values
        : "" || userDetail?.isTenant
        ? null
        : formState?.values,
      headers: {
        cooljwt: Token,
      },
    })
      .then((response) => {
        setFilesData(
          user.isVendor ? response?.data?.Invoice : response?.data?.Invoice
        );
        setTableData(
          user.isVendor ? response?.data?.Invoice : response?.data?.Invoice
        );
        setIsLoading(false);
        setTotalCount(response?.data?.totalNumOfItems);
      })
      .catch((error) => {
        console.log(error);
        if (error?.response) {
          error?.response?.status == 401 && dispatch(setIsTokenExpired(true));
        }
        console.log(
          typeof error?.response != "undefined"
            ? error?.response?.data
            : error?.message
        );
        setIsLoading(false);
      });
  };

  //Close Models
  const closeTagModal = () => {
    setTagModal(false);
  };
  //Close Models
  const closeMarkAsReceivedModel = () => {
    setMarkAsReceivedModel(false);
  };
  const closePaymentModel = () => {
    setinitPaymentModel(false);
  };
  const closeInvoiceModel = () => {
    // setEditInvoiceModel(null)
    setEditInvoiceModel(false);
    setAnimateTable(true);
  };
  const closeTaskModal = () => {
    setTaskModal(false);
  };
  const closeQrModal = () => {
    setQrModal(false);
  };
  const setFilter = async (data) => {
    setFormState((formState) => ({
      ...formState,
      values: data.values,
      filters: data.filters,
    }));
  };
  const getFilterData = async () => {
    setIsLoading(true);
    let data = {
      invoiceId: "",
      status: [parseInt(isFilter)],
      submitStart: null,
      submitEnd: null,
      amountTo: null,
      amountfrom: null,
      partialPaid: null,
      fullPaid: null,
      notPaid: null,
      invoiceType: null,
    };
    await axios({
      method: "post", //you can set what request you want to be
      url: userDetail?.isVendor
        ? `${process.env.REACT_APP_LDOCS_API_URL}/invoice/getInvoiceByVendor?page=${page}&items=${rowsPerPage}`
        : `${process.env.REACT_APP_LDOCS_API_URL}/invoice/getInvoiceOrg/${userDetail?.orgDetail?.organizationId}?page=${page}&items=${rowsPerPage}`,
      data: data,
      headers: {
        cooljwt: Token,
      },
    })
      .then((response) => {
        let invoices = response?.data || [];
        setTableData(invoices?.Invoice);
        setTotalCount(response?.data?.totalNumOfItems);
        setIsLoading(false);
        dispatch(filterInvoice(null));
      })
      .catch((error) => {
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true));
        }
        console.log(
          typeof error.response != "undefined"
            ? error.response.data
            : error.message
        );
        setIsLoading(false);
      });
    // setTableData(files);
    setShowFiltersModel(false);
  };

  //Set File Data
  const [fileData, setFileData] = React.useState();
  const [type, setType] = React.useState("");
  const initWorkFLow = ({ event, props }) => {
    if (props.markedAs == "unread") {
      msgAlert("Review Invoice Before Initiate Workflow...");
    } else if (props.initWorkFlow) {
      msgAlert("WorkFlow Already Initiated...");
    } else {
      setFileData(props);
      setInitWorkFlowModal(true);
    }
  };

  const initWorkFLowAction = (props) => {
    if (props.markedAs == "unread") {
      msgAlert("Review Invoice Before Initiate Workflow...");
    } else if (props.initWorkFlow) {
      msgAlert("WorkFlow Already Initiated...");
    } else {
      setFileData(props);
      setInitWorkFlowModal(true);
    }
  };
  //Open Advance View From through Awesome Menu
  const viewQrViewFromAwesomeMenu = ({ event, props }) => {
    viewQrView(props);
  };
  //Open BlockChain View From through Awesome Menu
  const viewBlockChainViewFromAwesomeMenu = ({ event, props }) => {
    viewBlockChainView(props);
  };
  //Print
  // const print = () => {
  //   fetch(pdfUrl).then(function(response) {
  //     return response.blob();
  // }).then(function(myBlob) {
  //     var objectURL = URL.createObjectURL(myBlob);
  //     document.querySelector('#pdf-frame').src = '';
  //     document.querySelector('#pdf-frame').src = objectURL;
  //     objectURL = URL.revokeObjectURL(myBlob);
  // }).then(
  //     function() {
  //         window.setTimeout(function() {
  //             document.querySelector('#pdf-frame').contentWindow.print();
  //         }, 1000)
  //     });
  //   }

  //Right Click Menu
  const MyAwesomeMenu = () => (
    <Menu id="menu_id" theme={theme.dark} animation={animation.zoom}>
      {!isVendor ? (
        <Item onClick={viewBlockChainViewFromAwesomeMenu}>
          <ClearAllIcon />
          &nbsp;&nbsp;BlockChain View
        </Item>
      ) : (
        ""
      )}
      <Separator />
      <Item onClick={viewQrViewFromAwesomeMenu}>
        <ViewModuleIcon />
        &nbsp;&nbsp;Invoice View
      </Item>
      <Separator />
      {!isVendor && userDetail?.tenantConfigs?.autoInitWorkFlow == false ? (
        <Item onClick={initWorkFLow}>
          <Send />
          &nbsp;&nbsp;Initialize WorkFlow
        </Item>
      ) : (
        ""
      )}
    </Menu>
  );

  const getSteps = (step) => {
    step = {
      ...step,
      forward: step?.forward
        ?.map((step) => ({ ...step, showReason: true }))
        .concat(
          step?.delegate?.map((step) => ({ ...step, showReason: false }))
        ),
    };
    let filteredStep = step?.forward.filter((x) => x !== undefined);
    return {
      ...step,
      forward: filteredStep?.sort(
        (a, b) => new Date(a?.date) - new Date(b?.date)
      ),
    };
  };

  const showFwdNotes = (step) => {
    if (step === "initialReview") {
      let step = getSteps(initialReview);
      setShowFwdData(step);
    } else if (step === "reviewStep") {
      let step = getSteps(review);
      setShowFwdData(step);
    } else {
      let step = getSteps(approve);
      setShowFwdData(step);
    }
    setShowFwdDetails(true);
  };

  return (
    <div>
      {/* View File */}
      {isViewing ? (
        <Animated
          animationIn="bounceInRight"
          animationOut="bounceOutLeft"
          animationInDuration={1000}
          animationOutDuration={1000}
          isVisible={animatePdf}
        >
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12} className={classes.center}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <h4 className={classes.cardTitleText}>
                      Invoice:{" "}
                      {pdfModalData.invoiceId +
                        " Version: " +
                        pdfModalData.version}
                    </h4>
                  </CardIcon>
                  <Button
                    color="danger"
                    round
                    style={{ float: "right" }}
                    className={classes.marginRight}
                    onClick={() => goBack()}
                  >
                    Go Back
                  </Button>
                  {/* <Button
                    color="info"
                    round
                    style={{ float: "right" }}
                    className={classes.marginRight}
                    onClick={() => console.log('22323')}
                  >
                    Print Document
                  </Button> */}
                </CardHeader>
                <CardBody>
                  {/* <PDFViewer
                    document={{
                      url: pdfUrl,
                    }}
                    navbarOnTop={true}
                    scale={2}
                  /> */}
                  <Iframe
                    url={pdfUrl}
                    width="100%"
                    id="myId"
                    allow="print 'none'; download 'none'"
                    className="myClassname"
                    height={window.screen.height}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Animated>
      ) : (
        ""
      )}
      {/* Open BlockChain View */}
      {isViewingBlockChainView ? (
        <Animated
          animationIn="bounceInRight"
          animationOut="bounceOutLeft"
          animationInDuration={1000}
          animationOutDuration={1000}
          isVisible={animateBlockChain}
        >
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12} className={classes.center}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <h4 className={classes.cardTitleText}>Blockchain View</h4>
                  </CardIcon>
                  <Button
                    color="danger"
                    round
                    style={{ float: "right" }}
                    className={classes.marginRight}
                    onClick={() => goBack()}
                  >
                    Go Back
                  </Button>
                </CardHeader>
                <CardBody>
                  {blockChainData ? (
                    <VerticalLinearStepper data={blockChainData} />
                  ) : (
                    <CircularProgress />
                  )}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Animated>
      ) : (
        ""
      )}
      {/* Tag Model */}
      {tagModal ? (
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} className={classes.center}>
            <Dialog
              classes={{
                root: classes.center + " " + classes.modalRoot,
                paper: classes.modal,
              }}
              fullWidth={true}
              maxWidth={"sm"}
              open={tagModal}
              TransitionComponent={Transition}
              keepMounted
              onClose={closeTagModal}
              aria-labelledby="tag-modal-slide-title"
              aria-describedby="tag-modal-slide-description"
            >
              <DialogContent
                id="tag-modal-slide-description"
                className={classes.modalBody}
              >
                <FileTags closeTagModal={closeTagModal} fileData={row} />
              </DialogContent>
            </Dialog>
          </GridItem>
        </GridContainer>
      ) : (
        ""
      )}
      {/* Mark As Received Model */}
      {markAsReceivedModel ? (
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} className={classes.center}>
            <Dialog
              classes={{
                root: classes.center + " " + classes.modalRoot,
                paper: classes.modal,
              }}
              fullWidth={true}
              maxWidth={"sm"}
              open={markAsReceivedModel}
              TransitionComponent={Transition}
              keepMounted
              onClose={closeMarkAsReceivedModel}
              aria-labelledby="tag-modal-slide-title"
              aria-describedby="tag-modal-slide-description"
            >
              <DialogContent
                id="tag-modal-slide-description"
                className={classes.modalBody}
              >
                <FileReceived
                  closeFileReceivedModal={closeMarkAsReceivedModel}
                  fileData={row}
                  loadFiles={getMyFiles}
                />
              </DialogContent>
            </Dialog>
          </GridItem>
        </GridContainer>
      ) : (
        ""
      )}
      {/* Mark As Payment Model */}
      {initPaymentModel ? (
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} className={classes.center}>
            <Dialog
              classes={{
                root: classes.center + " " + classes.modalRoot,
                paper: classes.modal,
              }}
              fullWidth={true}
              maxWidth={"md"}
              scroll="body"
              open={initPaymentModel}
              TransitionComponent={Transition}
              keepMounted
              onClose={closePaymentModel}
              aria-labelledby="tag-modal-slide-title"
              aria-describedby="tag-modal-slide-description"
            >
              <DialogContent
                id="tag-modal-slide-description"
                className={classes.modalBody}
              >
                <InitiatePayment
                  closeModal={closePaymentModel}
                  fileData={row}
                  loadFiles={getMyFiles}
                />
              </DialogContent>
            </Dialog>
          </GridItem>
        </GridContainer>
      ) : (
        ""
      )}
      {/* Mark As Payment Model */}
      {exportToFusionModel ? (
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} className={classes.center}>
            <Dialog
              classes={{
                root: classes.center + " " + classes.modalRoot,
                paper: classes.modal,
              }}
              fullWidth={true}
              maxWidth={"sm"}
              scroll="body"
              open={exportToFusionModel}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => setExportToFusionModel(false)}
              aria-labelledby="tag-modal-slide-title"
              aria-describedby="tag-modal-slide-description"
            >
              <DialogContent
                id="tag-modal-slide-description"
                className={classes.modalBody}
              >
                <ExportToFusion
                  closeModal={() => setExportToFusionModel(false)}
                  fileData={row}
                  loadFiles={getMyFiles}
                />
              </DialogContent>
            </Dialog>
          </GridItem>
        </GridContainer>
      ) : (
        ""
      )}
      {/* Mark As edit Model */}
      {/* {editInvoice ? (
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} className={classes.center}>
            <Dialog
              classes={{
                root: classes.center + " " + classes.modalRoot,
                paper: classes.modal,
              }}
              fullWidth={true}
              maxWidth={"lg"}
              scroll="body"
              open={editInvoice}
              // TransitionComponent={TransitionLeft}
              keepMounted
              onClose={closeInvoiceModel}
              aria-labelledby="tag-modal-slide-title"
              aria-describedby="tag-modal-slide-description"
            >
              <DialogContent
                id="tag-modal-slide-description"
                className={classes.modalBody}
              >
                <CreateInvoice edit={editInvoice} closeModal={closeInvoiceModel} fileData={row} />
              </DialogContent>
            </Dialog>
          </GridItem>
        </GridContainer>
      ) : (
        ""
      )} */}
      {/* Task Model */}
      {taskModal ? (
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} className={classes.center}>
            <Dialog
              classes={{
                root: classes.center + " " + classes.modalRoot,
                paper: classes.modal,
              }}
              fullWidth={true}
              maxWidth={"sm"}
              open={taskModal}
              TransitionComponent={Transition}
              keepMounted
              onClose={closeTaskModal}
              aria-labelledby="tag-modal-slide-title"
              aria-describedby="tag-modal-slide-description"
            >
              <DialogContent
                id="tag-modal-slide-description"
                className={classes.modalBody}
              >
                <FileTasks closeTaskModal={closeTaskModal} fileData={row} />
              </DialogContent>
            </Dialog>
          </GridItem>
        </GridContainer>
      ) : (
        ""
      )}
      {/* Advance View Model */}
      {qrModal ? (
        <Animated
          animationIn="bounceInRight"
          animationOut="bounceOutLeft"
          animationInDuration={1000}
          animationOutDuration={1000}
          isVisible={animateQr}
        >
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12} className={classes.center}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <h4 className={classes.cardTitleText}>360&#176; View</h4>
                  </CardIcon>
                  <Button
                    color="danger"
                    round
                    style={{ float: "right" }}
                    className={classes.marginRight}
                    onClick={() => goBack()}
                  >
                    Go Back
                  </Button>
                </CardHeader>
                <CardBody>
                  <FileAdvanceView isVendor={isVendor} fileData={row} />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Animated>
      ) : (
        ""
      )}
      {/* Init Workflow Model */}
      {initWorkFlowModal ? (
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} className={classes.center}>
            <Dialog
              classes={{
                root: classes.center + " " + classes.modalRoot,
                paper: classes.modal,
              }}
              fullWidth={true}
              maxWidth={"md"}
              open={initWorkFlowModal}
              scroll="body"
              TransitionComponent={Transition}
              keepMounted
              onClose={() => setInitWorkFlowModal(false)}
              aria-labelledby="classic-modal-slide-title"
              aria-describedby="classic-modal-slide-description"
            >
              <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}
              >
                <InitWorkflow
                  closeModal={() => setInitWorkFlowModal(false)}
                  fileData={fileData}
                  loadFiles={getMyFiles}
                  type={type}
                />
              </DialogContent>
            </Dialog>
          </GridItem>
        </GridContainer>
      ) : (
        ""
      )}

      {/* Init Workflow Model */}
      {/* {showFiltersModel ? (
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} className={classes.center}>
            <Dialog
              classes={{
                root: classes.center + " " + classes.modalRoot,
                paper: classes.modal,
              }}
              fullWidth={true}
              maxWidth={"md"}
              open={showFiltersModel}
              scroll="body"
              TransitionComponent={Transition}
              keepMounted
              onClose={() => setShowFiltersModel(false)}
              aria-labelledby="classic-modal-slide-title"
              aria-describedby="classic-modal-slide-description"
            >
              <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}
              >
                <Filters
                  closeModal={() => setShowFiltersModel(false)}
                  loadFiles={getMyFiles}
                />
              </DialogContent>
            </Dialog>
          </GridItem>
        </GridContainer>
      ) : (
        ""
      )} */}
      {animateTable ? (
        <Animated
          animationIn="bounceInRight"
          animationOut="bounceOutLeft"
          animationInDuration={1000}
          animationOutDuration={1000}
          isVisible={animateTable}
        >
          {/* Awesome Menu */}
          <MyAwesomeMenu />
          <GridItem xs={12} md={12} lg={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} lg={12}>
                <Card>
                  <CardHeader color="info" icon>
                    <CardIcon color="info">
                      <h4 className={classes.cardTitleText}>{componentName}</h4>
                    </CardIcon>
                    <p style={{ color: "gray" }}>
                      Note: Right click on any file to see multiple options
                    </p>
                    <Button
                      color="danger"
                      round
                      style={{ float: "right" }}
                      className={classes.marginRight}
                      onClick={() => setShowFiltersModel(true)}
                    >
                      Filters
                    </Button>
                    {filesData?.length > 0 ? (
                      <Button
                        color="danger"
                        round
                        style={{ float: "right" }}
                        className={classes.marginRight}
                        onClick={() => exportToCSV()}
                      >
                        Export
                      </Button>
                    ) : (
                      ""
                    )}
                    <Tooltip
                      id="tooltip-top"
                      title="Refresh"
                      style={{ float: "right" }}
                      placement="bottom"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Button
                        onClick={() => getMyFiles(userDetail, false)}
                        simple
                        color="info"
                        justIcon
                      >
                        <Refresh className={classes.underChartIcons} />
                      </Button>
                    </Tooltip>
                  </CardHeader>
                  <CardBody>
                    {isLoading ? (
                      <LinearProgress />
                    ) : (
                      <>
                        <TablePagination
                          component="div"
                          count={totalCount}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                        <ReactTable
                          data={data}
                          // page={1}
                          sortable={false}
                          columns={
                            !isVendor
                              ? [
                                  {
                                    Header: "Invoice Number",
                                    accessor: "invoiceId",
                                    filterable: true,
                                    filter: "fuzzyText",
                                    sortType: "basic",
                                  },
                                  {
                                    Header: "Status",
                                    accessor: "status",
                                    width: 190,
                                  },
                                  // {
                                  //   Header: 'Invoice Date',
                                  //   accessor: 'invoiceDate'
                                  // },
                                  {
                                    Header: "Submit Date",
                                    accessor: "createdDate",
                                  },
                                  {
                                    Header: "Supplier Name",
                                    accessor: "vendorName",
                                  },
                                  {
                                    Header: "Amount",
                                    accessor: "netAmt",
                                  },
                                  {
                                    Header: "Invoice Type",
                                    accessor: "invoiceType",
                                    filterable: true,
                                    filter: "fuzzyText",
                                    sortType: "basic",
                                  },
                                  {
                                    Header: "PO",
                                    accessor: "po",
                                    filterable: true,
                                    filter: "fuzzyText",
                                    sortType: "basic",
                                    width: 60,
                                  },
                                  {
                                    Header: "Reviewed",
                                    accessor: "reviewed",
                                    // width: 60
                                  },
                                  {
                                    Header: "Approved",
                                    accessor: "approved",
                                    // width: 50
                                  },
                                  {
                                    Header: "Actions",
                                    accessor: "actions",
                                    filterable: false,
                                  },
                                ]
                              : [
                                  {
                                    Header: "Invoice Number",
                                    accessor: "invoiceId",
                                    filterable: true,
                                    filter: "fuzzyText",
                                    sortType: "basic",
                                  },
                                  {
                                    Header: "Status",
                                    accessor: "status",
                                    width: 200,
                                  },
                                  {
                                    Header: "Submit Date",
                                    accessor: "createdDate",
                                  },
                                  {
                                    Header: "Invoice Date",
                                    accessor: "invoiceDate",
                                  },
                                  {
                                    Header: "Customer Name",
                                    accessor: "customerName",
                                  },
                                  {
                                    Header: "Amount",
                                    accessor: "netAmt",
                                  },
                                  {
                                    Header: "Po Number",
                                    accessor: "po",
                                    filterable: true,
                                    filter: "fuzzyText",
                                    sortType: "basic",
                                  },
                                  {
                                    Header: "Actions",
                                    accessor: "actions",
                                    filterable: false,
                                  },
                                ]
                          }
                          className="-striped -highlight"
                          defaultPageSize={rowsPerPage}
                        />
                        <TablePagination
                          component="div"
                          count={totalCount}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </>
                    )}
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </GridItem>
        </Animated>
      ) : (
        ""
      )}
      {editInvoice ? (
        <Animated
          animationIn="bounceInRight"
          animationOut="bounceOutLeft"
          animationInDuration={1000}
          animationOutDuration={1000}
          isVisible={editInvoice}
        >
          <CreateInvoice
            edit={editInvoice}
            dratInvoice={draftInvoice}
            loadFiles={getMyFiles}
            closeModal={closeInvoiceModel}
            editHandler={editHandler}
            setEditInvoiceModel={setEditInvoiceModel}
            setAnimateTable={setAnimateTable}
            fileData={row}
          />
        </Animated>
      ) : (
        ""
      )}
      {openTrackingModal ? (
        <Dialog
          classes={{
            root: classes.center + " " + classes.modalRoot,
            paper: classes.modal,
          }}
          fullWidth={true}
          maxWidth={"lg"}
          open={openTrackingModal}
          // TransitionComponent={Transition}
          keepMounted
          onClose={() => setOpenTrackingModal(false)}
          aria-labelledby="tag-modal-slide-title"
          aria-describedby="tag-modal-slide-description"
        >
          <DialogContent
            id="tag-modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <h4 className={classes.cardTitle}>Tracking Status</h4>
                  </CardIcon>
                </CardHeader>
                <CardBody>
                  {trackingLoading ? (
                    <CircularProgress />
                  ) : (
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow className={classes.TableRow}>
                          <TableCell className={classes.TableID}>
                            Review / Approve
                          </TableCell>
                          <TableCell className={classes.TableID}>
                            Assigned To
                          </TableCell>
                          <TableCell className={classes.TableID}>
                            Assigned Date
                          </TableCell>
                          <TableCell className={classes.TableCell}>
                            Status
                          </TableCell>
                          <TableCell className={classes.TableCell}>
                            Action Date
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody style={{ paddingBottom: 5 }}>
                        <TableRow className={classes.TableRow}>
                          <TableCell className={classes.TableCell}>
                            Initial Review
                            <br />
                            (Requester)
                            {initialReview?.isForward || review?.isDelegate ? (
                              <IconButton
                                onClick={() => showFwdNotes("initialReview")}
                              >
                                <InfoIcon />
                              </IconButton>
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell>
                            {initialReview?.reviewedBy || "-"}
                            <br />
                            {initialReview?.assignTo || "-"}
                          </TableCell>
                          <TableCell>
                            {initialReview?.assignDate
                              ? formatDateTime(initialReview?.assignDate)
                              : "-"}
                          </TableCell>
                          <TableCell>
                            {initialReview?.status == "completed"
                              ? initialReview?.status.toUpperCase()
                              : initialReview?.status == "correctionRequired"
                              ? initialReview?.status.toUpperCase()
                              : initialReview?.status == "rejected"
                              ? initialReview?.status.toUpperCase()
                              : "PENDING" || "PENDING"}
                          </TableCell>
                          <TableCell>
                            {initialReview?.status !== "pending" &&
                            initialReview?.date
                              ? formatDateTime(initialReview?.date)
                              : "-"}
                          </TableCell>
                        </TableRow>
                        <TableRow className={classes.TableRow}>
                          <TableCell className={classes.TableCell}>
                            Review
                            <br />
                            (Accounting Specialist)
                            {review?.isForward || review?.isDelegate ? (
                              <IconButton
                                onClick={() => showFwdNotes("reviewStep")}
                              >
                                <InfoIcon />
                              </IconButton>
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell>
                            {review?.reviewedBy || "-"}
                            <br />
                            {review?.assignTo || "-"}
                          </TableCell>
                          <TableCell>
                            {review?.assignDate
                              ? formatDateTime(review?.assignDate)
                              : "-"}
                          </TableCell>
                          <TableCell>
                            {review?.status == "completed"
                              ? review?.status.toUpperCase()
                              : review?.status == "correctionRequired"
                              ? review?.status.toUpperCase()
                              : review?.status == "rejected"
                              ? review?.status.toUpperCase()
                              : "PENDING" || "PENDING"}
                          </TableCell>
                          <TableCell>
                            {review?.status !== "inProgress" && review?.date
                              ? formatDateTime(review?.date)
                              : "-"}
                          </TableCell>
                        </TableRow>
                        <TableRow className={classes.TableRow}>
                          <TableCell className={classes.TableCell}>
                            Approve
                            <br />
                            (Accounting Manager)
                            {approve?.isForward || approve?.isDelegate ? (
                              <IconButton
                                onClick={() => showFwdNotes("approveStep")}
                              >
                                <InfoIcon />
                              </IconButton>
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell>
                            {approve?.approvedBy || "-"}
                            <br />
                            {approve?.assignTo || "-"}
                          </TableCell>
                          <TableCell>
                            {approve?.assignDate
                              ? formatDateTime(approve?.assignDate)
                              : "-"}
                          </TableCell>
                          <TableCell>
                            {approve?.status == "completed"
                              ? approve?.status.toUpperCase()
                              : approve?.status == "correctionRequired"
                              ? approve?.status.toUpperCase()
                              : approve?.status == "rejected"
                              ? approve?.status.toUpperCase()
                              : "PENDING" || "PENDING"}
                          </TableCell>
                          <TableCell>
                            {approve?.status !== "pending" && approve?.date
                              ? formatDateTime(approve?.date)
                              : "-"}
                          </TableCell>
                        </TableRow>
                        <TableRow className={classes.TableRow}>
                          <TableCell className={classes.TableCell}>
                            Approve and Export
                            <br />
                            (Requester)
                          </TableCell>
                          <TableCell>
                            {approveandexported?.exportedBy || "-"}
                            <br />
                            {approveandexported?.assignTo || "-"}
                          </TableCell>
                          <TableCell>
                            {approveandexported?.assignDate
                              ? formatDateTime(approveandexported?.assignDate)
                              : "-"}
                          </TableCell>
                          <TableCell>
                            {approveandexported?.status == "completed"
                              ? approveandexported?.status.toUpperCase()
                              : "PENDING" || "PENDING"}
                          </TableCell>
                          <TableCell>
                            {approveandexported?.status !== "pending" &&
                            approveandexported?.date
                              ? formatDateTime(approveandexported?.date)
                              : "-"}
                          </TableCell>
                        </TableRow>

                        <TableRow className={classes.TableRow}>
                          <TableCell className={classes.TableCell}>
                            Invoice Status
                            {/* <br />
                            (Requester) */}
                          </TableCell>
                          <TableCell>
                            {canceled?.paidBy || "-"}
                            <br />
                            {canceled?.assignTo || "-"}
                          </TableCell>
                          <TableCell>
                            {canceled?.assignDate
                              ? formatDateTime(canceled?.assignDate)
                              : "-"}
                          </TableCell>
                          <TableCell>
                            {canceled?.status == "inProgress"
                              ? "PENDING"
                              : canceled?.status == "completed"
                              ? "COMPLETED"
                              : canceled?.status == "Canceled"
                              ? "CANCELED"
                              : "-"}
                          </TableCell>
                          <TableCell>
                            {canceled?.status !== "pending" && canceled?.date
                              ? formatDateTime(canceled?.date)
                              : "-"}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  )}
                </CardBody>
              </Card>
              <Button
                color="danger"
                className={classes.registerButton}
                onClick={() => closeTrackingModal()}
                round
                style={{ float: "right", marginLeft: "auto" }}
              >
                CLOSE
              </Button>
            </GridContainer>
          </DialogContent>
        </Dialog>
      ) : (
        ""
      )}
      {showFwdDetails ? (
        <Dialog
          classes={{
            root: classes.center + " " + classes.modalRoot,
            paper: classes.modal,
          }}
          fullWidth={true}
          maxWidth={"md"}
          open={showFwdDetails}
          // TransitionComponent={Transition}
          keepMounted
          onClose={() => setShowFwdDetails(false)}
          aria-labelledby="tag-modal-slide-title"
          aria-describedby="tag-modal-slide-description"
        >
          <DialogContent
            id="tag-modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <h4 className={classes.cardTitle}>
                      Forward/Deligate Details
                    </h4>
                  </CardIcon>
                </CardHeader>
                <CardBody>
                  {trackingLoading ? (
                    <CircularProgress />
                  ) : (
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Foward/Deligate User</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody style={{ paddingBottom: 5 }}>
                        {showFwdData &&
                          showFwdData?.forward?.map((fwd, index) => (
                            <>
                              <TableRow
                                className={classes.TableRow}
                                key={index}
                              >
                                <TableCell className={classes.TableCell}>
                                  {formatDateTime(fwd?.date)}
                                </TableCell>
                                <TableCell>{fwd?.noteMsg}</TableCell>
                              </TableRow>
                              {fwd?.showReason && (
                                <TableRow
                                  className={classes.TableRow}
                                  key={index}
                                >
                                  <TableCell colSpan={3}>
                                    <b>Reason:</b> {fwd?.reason}
                                  </TableCell>
                                </TableRow>
                              )}
                            </>
                          ))}
                      </TableBody>
                    </Table>
                  )}
                </CardBody>
              </Card>
              <Button
                color="danger"
                className={classes.registerButton}
                onClick={() => setShowFwdDetails(false)}
                round
                style={{ float: "right", marginLeft: "auto" }}
              >
                CLOSE
              </Button>
            </GridContainer>
          </DialogContent>
        </Dialog>
      ) : (
        ""
      )}
      <SwipeableDrawer
        anchor={"right"}
        open={showFiltersModel}
        onClose={() => setShowFiltersModel(false)}
        // onOpen={}
      >
        <Animated
          animationIn="bounceInRight"
          animationOut="bounceOutLeft"
          animationInDuration={1000}
          animationOutDuration={1000}
          isVisible={showFiltersModel}
        >
          <Filters
            filters={formState.filters}
            values={formState.values}
            closeModal={() => setShowFiltersModel(false)}
            setFilters={setFilter}
            isVendor={isVendor}
          />
        </Animated>
      </SwipeableDrawer>
    </div>
  );
}
