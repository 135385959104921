import React, { useState, useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  CircularProgress,
  MenuItem,
  Chip,
  Tooltip,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  DialogContent,
  Dialog,
  TablePagination,
} from "@material-ui/core";

// @material-ui/icons
import SearchIcon from "@material-ui/icons/Search";
import SelectAllIcon from "@material-ui/icons/SelectAll";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import NavPills from "components/NavPills/NavPills.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import Table from "components/Table/Table.js";
import CardHeader from "components/Card/CardHeader.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { Animated } from "react-animated-css";
import BlockChain from "./BlockChain";
import VerticalLinearStepper from "../../Components/VerticalStepper";
import Tags from "./Tags";
import Approval from "./Approval";
import Review from "./Review";
import Autocomplete from "@material-ui/lab/Autocomplete";
import dateFormat from "dateformat";
import axios from "axios";
import jwt from "jsonwebtoken";
import { useDispatch, useSelector } from "react-redux";
import FileAdvanceView from "../Invoices/AdvanceView/FileAdvanceView";
import {
  addZeroes,
  currentTracking,
  formatDate,
  formatDateTime,
} from "../Functions/Functions";
import { setIsTokenExpired } from "actions";
import { msgAlert } from "Functions";
import TrackingModal from "./TrackingModal";
import ForwardTaskModal from "./ForwardTaskModal";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  cardTitleText: {
    color: "white",
  },
  buttonRight: {},
};

const useStyles = makeStyles(styles);

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}
export default function Verify() {
  const isAr = useSelector((state) => state.userReducer.isAr);
  let permissions = useSelector((state) => state.userReducer.permissions);
  const [animateFileInfo, setAnimateFileInfo] = useState(false);
  const [disabledSearch, setDisabledSearch] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [fileName, setFileName] = React.useState(null);
  const [Selected, setSelected] = React.useState(null);
  const [fileData, setFileData] = React.useState([]);
  const [blockChainData, setBlockChainData] = React.useState(null);
  const [file, setFile] = React.useState(null);
  //   Test Data
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [invoices, setInvoices] = React.useState([]);
  const [inputValue, setInputValue] = React.useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [trackingLoading, setTrackingLoading] = React.useState(false);
  const [openTrackingModal, setOpenTrackingModal] = React.useState(false);
  const [initialReview, setInitialReview] = React.useState("");
  const [review, setReview] = React.useState("");
  const [approve, setApprove] = React.useState("");
  const [approveandexported, setApproveAndExported] = React.useState("");
  const [canceled, setCanceled] = React.useState("");
  const [totalCount, setTotalCount] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [_PurchaseOrderList, setPurchaseOrderList] = React.useState([]);
  const [poLoading, setPoLoading] = React.useState(false);
  const [vendorLoading, setVendorLoading] = React.useState(false);
  const [showFwdDetails, setShowFwdDetails] = React.useState(false);
  const [showFwdData, setShowFwdData] = React.useState(null);
  const [formState, setFormState] = React.useState({
    selectedPurchaseOrder: "",
    poInputValue: "",
    supplierInputValue: "",
    vendors: [],
    selectedVendor: "",
    invoiceId: "",
    status: "",
    values: {
      status: "",
      comments: "",
    },
    errors: {
      status: "",
      reviewComments: "",
    },
  });
  const [filters, setFilters] = React.useState({
    po: "",
    invoiceId: "",
    status: "",
    supplier: "",
  });
  const loading = open && options.length === 0;

  const { ap, ar } = permissions || {};
  let { actionDesk, financeDesk, others, invoiceDesk } = ap || {};

  const isAllRouteDisabled = (rts) => {
    if (typeof rts == "object") {
      let all = [];
      Object.keys(rts).map((item) => {
        if (
          item != "name" &&
          item != "enable" &&
          typeof rts[item] == "object"
        ) {
          all.push(rts[item]?.enable ? true : false);
        }
      });
      return all.every((item) => item == false);
    }
  };

  // if(!enabled){
  //     others = false;
  // }
  let isInvoiceDeskDisable = isAllRouteDisabled(invoiceDesk);
  let isfinanceDeskDisable = isAllRouteDisabled(financeDesk);

  const Token =
    useSelector((state) => state.userReducer.Token) ||
    localStorage.getItem("cooljwt");
  const dispatch = useDispatch();
  const userDetails = jwt.decode(Token);

  useEffect(() => {
    if (filters.po || filters.invoiceId || filters.status || filters.supplier) {
      getFile();
    }
  }, [page, rowsPerPage, filters]);

  useEffect(() => {
    _getSuppliers();
    _getPurchaseOrders();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const selectFile = async (fileObject, index) => {
    // document.getElementById("selectFile"+index).setAttribute("color","black")
    if (Selected == index) {
      setSelected(null);
      setFile(null);
      setBlockChainData(null);
    } else {
      setFile(null);

      setTimeout(() => {
        setSelected(index);
        setFile(fileObject);
        setBlockChainData(null);
      }, 1000);
    }
  };

  const classes = useStyles();

  const getFile = (clearFilter) => {
    const selectedOption = invoices.find(
      (i) => i.originalInvoiceId == filters?.invoiceId?.name
    );
    setBlockChainData(null);
    setSelected(null);
    setFile(null);
    setAnimateFileInfo(false);
    setIsSearching(true);
    axios({
      method: "post", //you can set what request you want to be
      url: isAr
        ? `${process.env.REACT_APP_LDOCS_API_URL}/AR/invoiceFullSearchOrgAR`
        : userDetails.isTenant
        ? `${process.env.REACT_APP_LDOCS_API_URL}/invoice/invoiceFullSearch`
        : `${process.env.REACT_APP_LDOCS_API_URL}/invoice/invoiceFullSearchOrg`,
      data: userDetails.isTenant
        ? {
            invoiceId:
              !clearFilter && selectedOption
                ? selectedOption?.originalInvoiceId
                : null,
            version:
              !clearFilter && selectedOption ? selectedOption?.version : null,
            status: !clearFilter ? filters.status : null,
            supplier:
              !clearFilter && filters.supplier
                ? filters.supplier?.level1?.Supplier
                : null,
            po: !clearFilter && filters.po ? filters.po : null,
          }
        : {
            organizationId: userDetails.orgDetail.organizationId,
            isRequester: isInvoiceDeskDisable == false ? true : false,
            isFinance: isfinanceDeskDisable == false ? true : false,
            invoiceId:
              !clearFilter && selectedOption
                ? selectedOption?.originalInvoiceId
                : null,
            version:
              !clearFilter && selectedOption ? selectedOption?.version : null,
            status: !clearFilter ? filters.status : null,
            supplier:
              !clearFilter && filters.supplier
                ? filters.supplier?.level1?.Supplier
                : null,
            po: !clearFilter && filters.po ? filters.po : null,
          },
      headers: { cooljwt: Token },
    })
      .then((response) => {
        if (clearFilter) {
          setFileData([]);
          setAnimateFileInfo(false);
          setIsSearching(true);
          return;
        }
        setFileData(response.data);
        setAnimateFileInfo(true);
        setIsSearching(false);
      })
      .catch((error) => {
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true));
        }
        setFileData([]);
        console.log(
          typeof error.response != "undefined"
            ? error.response.data
            : error.message
        );
        setAnimateFileInfo(true);
        setIsSearching(false);
      });
  };

  const getFilterFiles = (value) => {
    setBlockChainData(null);
    setSelected(null);
    setFile(null);
    setAnimateFileInfo(false);
    setIsSearching(true);
    axios({
      method: "post", //you can set what request you want to be
      url: `${process.env.REACT_APP_LDOCS_API_URL}/invoice/getInvByTrackingStatus?page=${page}&items=${rowsPerPage}`,
      data: userDetails.isTenant
        ? {
            isRequester: isInvoiceDeskDisable == false ? true : false,
            isFinance: isfinanceDeskDisable == false ? true : false,
            status: value || filterStatus,
          }
        : {
            isRequester: isInvoiceDeskDisable == false ? true : false,
            isFinance: isfinanceDeskDisable == false ? true : false,
            status: value || filterStatus,
          },
      headers: { cooljwt: Token },
    })
      .then((response) => {
        setFileData(response?.data?.Invoices);
        setTotalCount(response?.data?.totalNumOfItems);
        setAnimateFileInfo(true);
        setIsSearching(false);
      })
      .catch((error) => {
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true));
        }
        setFileData([]);
        console.log(
          typeof error.response != "undefined"
            ? error.response.data
            : error.message
        );
        setAnimateFileInfo(true);
        setIsSearching(false);
      });
  };

  React.useEffect(() => {
    let active = true;

    // if (!loading) {
    //   return undefined;
    // }

    (async () => {
      if (options.length <= 0) {
        axios({
          method: "post",
          url: isAr
            ? `${process.env.REACT_APP_LDOCS_API_URL}/AR/invoicePartialSearchOrgAR`
            : userDetails.isTenant
            ? `${process.env.REACT_APP_LDOCS_API_URL}/invoice/invoicePartialSearch`
            : `${process.env.REACT_APP_LDOCS_API_URL}/invoice/invoicePartialSearchOrg`,
          data: userDetails.isTenant
            ? {
                query: inputValue,
                status: filterStatus,
                isFinance: isfinanceDeskDisable == false ? true : false,
                isRequester: isInvoiceDeskDisable == false ? true : false,
              }
            : {
                query: inputValue,
                organizationId: userDetails.orgDetail.organizationId,
                isFinance: isfinanceDeskDisable == false ? true : false,
                isRequester: isInvoiceDeskDisable == false ? true : false,
                status: filterStatus,
              },
          headers: { cooljwt: Token },
        })
          .then(async (response) => {
            await sleep(130);
            const result = await response.data.result;
            setInvoices(result);
            setOptions(
              result.map((item) => {
                return { name: item.originalInvoiceId };
              })
            );
          })
          .catch((err) => {
            console.log(err);
          });
      }
    })();

    return () => {
      active = false;
    };
  }, [inputValue]);

  const handleFilter = async (event) => {
    event.persist();
    setFileName(null);
    setFormState((formState) => ({
      ...formState,
      selectedPurchaseOrder: "",
      selectedVendor: "",
    }));
    setFilterStatus(event.target.value);
    getFilterFiles(event.target.value);
  };

  const getSteps = (step) => {
    step = {
      ...step,
      forward: step?.forward
        ?.map((step) => ({ ...step, showReason: true }))
        .concat(
          step?.delegate?.map((step) => ({ ...step, showReason: false }))
        ),
    };
    let filteredStep = step?.forward.filter((x) => x !== undefined);
    return {
      ...step,
      forward: filteredStep?.sort(
        (a, b) => new Date(a?.date) - new Date(b?.date)
      ),
    };
  };

  const showFwdNotes = (step) => {
    if (step === "initialReview") {
      let step = getSteps(initialReview);
      setShowFwdData(step);
    } else if (step === "reviewStep") {
      let step = getSteps(review);
      setShowFwdData(step);
    } else {
      let step = getSteps(approve);
      setShowFwdData(step);
    }
    setShowFwdDetails(true);
  };

  const _trackingStatus = (trackingStatus) => {
    if (trackingStatus) {
      setOpenTrackingModal(true);
      // setTrackingLoading(true)
      setInitialReview(trackingStatus?.initialReview);
      setReview(trackingStatus?.underReview);
      setApprove(trackingStatus?.underApprove);
      setApproveAndExported(trackingStatus?.paymentInProcess);
      setCanceled(trackingStatus?.paid);
    } else {
      setOpenTrackingModal(false);
      setTrackingLoading(false);
      msgAlert("Tracking Status not Found");
    }
  };
  const closeTrackingModal = () => {
    setOpenTrackingModal(false);
    setInitialReview("");
    setReview("");
    setApprove("");
    setApproveAndExported("");
    setCanceled("");
  };

  const _getSuppliers = async () => {
    await axios({
      method: "get",
      url: `${process.env.REACT_APP_LDOCS_API_URL}/vendor/searchVendorsAll`,
      headers: { cooljwt: Token },
    })
      .then((res) => {
        setFormState((formState) => ({
          ...formState,
          vendors: res?.data?.result,
        }));
      })
      .catch((error) => {
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true));
        }
      });
  };

  const _getPurchaseOrders = async (supplierId, newInputValue) => {
    setPoLoading(true);
    let formData = {
      isAdmin: !userDetails.role.isAdmin
        ? isfinanceDeskDisable == false
          ? true
          : false
        : userDetails.role.isAdmin,
      displayName: userDetails?.displayName,
      po: newInputValue ? newInputValue : "",
      action: "PO",
      supplierId: supplierId ? supplierId : null,
      forPoOnly: true,
    };
    await axios({
      method: "post", //you can set what request you want to be
      url: `${process.env.REACT_APP_LDOCS_API_URL}/invoice/getPoForRecAll`,
      data: formData,
      headers: {
        cooljwt: Token,
      },
    })
      .then((res) => {
        setPoLoading(false);
        setPurchaseOrderList(res?.data);
      })
      .catch((error) => {
        setPoLoading(false);
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true));
        }
      });
  };

  const clearFilters = () => {
    setFilters((filters) => ({
      ...filters,
      po: "",
      invoiceId: "",
      status: "",
      supplier: "",
    }));
    setFormState((formState) => ({
      ...formState,
      selectedPurchaseOrder: "",
      poInputValue: "",
    }));
    getFile(true);
  };

  return (
    <div>
      {openTrackingModal ? (
        <Dialog
          classes={{
            root: classes.center + " " + classes.modalRoot,
            paper: classes.modal,
          }}
          fullWidth={true}
          maxWidth={"lg"}
          open={openTrackingModal}
          // TransitionComponent={Transition}
          keepMounted
          onClose={() => setOpenTrackingModal(false)}
          aria-labelledby="tag-modal-slide-title"
          aria-describedby="tag-modal-slide-description"
        >
          <DialogContent
            id="tag-modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <h4 className={classes.cardTitle}>Tracking Status</h4>
                  </CardIcon>
                </CardHeader>
                <CardBody>
                  {trackingLoading ? (
                    <CircularProgress />
                  ) : (
                    <TrackingModal
                      showFwdNotes={showFwdNotes}
                      initialReview={initialReview}
                      review={review}
                      approve={approve}
                      approveandexported={approveandexported}
                      canceled={canceled}
                    />
                  )}
                </CardBody>
              </Card>
              <Button
                color="danger"
                className={classes.registerButton}
                onClick={() => closeTrackingModal()}
                round
                style={{ float: "right", marginLeft: "auto" }}
              >
                CLOSE
              </Button>
            </GridContainer>
          </DialogContent>
        </Dialog>
      ) : (
        ""
      )}
      {showFwdDetails ? (
        <Dialog
          classes={{
            root: classes.center + " " + classes.modalRoot,
            paper: classes.modal,
          }}
          fullWidth={true}
          maxWidth={"md"}
          open={showFwdDetails}
          // TransitionComponent={Transition}
          keepMounted
          onClose={() => setShowFwdDetails(false)}
          aria-labelledby="tag-modal-slide-title"
          aria-describedby="tag-modal-slide-description"
        >
          <DialogContent
            id="tag-modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <h4 className={classes.cardTitle}>
                      Forward/Deligate Details
                    </h4>
                  </CardIcon>
                </CardHeader>
                <CardBody>
                  {trackingLoading ? (
                    <CircularProgress />
                  ) : (
                    <ForwardTaskModal showFwdData={showFwdData} />
                  )}
                </CardBody>
              </Card>
              <Button
                color="danger"
                className={classes.registerButton}
                onClick={() => setShowFwdDetails(false)}
                round
                style={{ float: "right", marginLeft: "auto" }}
              >
                CLOSE
              </Button>
            </GridContainer>
          </DialogContent>
        </Dialog>
      ) : (
        ""
      )}
      <Animated
        animationIn="bounceInRight"
        animationOut="bounceOutLeft"
        animationInDuration={1000}
        animationOutDuration={1000}
        isVisible={true}
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <h4 className={classes.cardTitleText}>Filters</h4>
                </CardIcon>
                <Button
                  color="danger"
                  size="small"
                  style={{ float: "right" }}
                  onClick={() => clearFilters()}
                  round
                >
                  Clear Filters
                </Button>
              </CardHeader>
              <CardBody>
                <GridContainer justify="center">
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                  >
                    <GridContainer justify="center">
                      <GridItem
                        xs={12}
                        sm={12}
                        md={10}
                        lg={6}
                        style={{ marginTop: "10px", marginBottom: "10px" }}
                      >
                        <Autocomplete
                          id="documentName"
                          value={filters.invoiceId}
                          onChange={(event, newValue) => {
                            setFileName(newValue);
                            if (newValue !== null) {
                              setDisabledSearch(false);
                              setFilters((filters) => ({
                                ...filters,
                                invoiceId: newValue,
                              }));
                            } else {
                              setDisabledSearch(true);
                              setFilters((filters) => ({
                                ...filters,
                                invoiceId: "",
                              }));
                            }
                          }}
                          open={open}
                          onOpen={() => {
                            setOpen(true);
                          }}
                          onClose={() => {
                            setOpen(false);
                          }}
                          getOptionSelected={(option, value) =>
                            option?.name === value?.name
                          }
                          getOptionLabel={(option) => option?.name}
                          options={options}
                          loading={loading}
                          inputValue={inputValue}
                          onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Invoice Number"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {loading ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}

                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                      </GridItem>

                      <GridItem
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        style={{ marginTop: "10px", marginBottom: "10px" }}
                      >
                        <TextField
                          className={classes.textField}
                          fullWidth={true}
                          label="Status"
                          name="Status"
                          onChange={(event) => {
                            setFilters((filters) => ({
                              ...filters,
                              status: event.target.value,
                            }));
                          }}
                          select
                          value={filters.status || ""}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                          >
                            Status
                          </MenuItem>
                          {/* <MenuItem value='ALL'>All</MenuItem> */}
                          <MenuItem value="Incomplete">Incomplete</MenuItem>
                          <MenuItem value="Pending">Pending</MenuItem>
                          <MenuItem value="Approved">Approved</MenuItem>
                          <MenuItem value="Approved and Exported">
                            Approved and Exported
                          </MenuItem>
                          <MenuItem value="Canceled">Canceled</MenuItem>
                          <MenuItem value="Paid">Paid</MenuItem>
                          <MenuItem value="Correction Required">
                            Correction Required
                          </MenuItem>
                          <MenuItem value="Rejected">Rejected</MenuItem>
                        </TextField>
                      </GridItem>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        style={{ marginTop: "10px", marginBottom: "10px" }}
                      >
                        <Autocomplete
                          id="filter-demo"
                          options={formState.vendors || []}
                          inputValue={formState.supplierInputValue || ""}
                          onInputChange={(event, newInputValue) => {
                            setFormState((formState) => ({
                              ...formState,
                              supplierInputValue: newInputValue,
                            }));
                          }}
                          value={filters.supplier || ""}
                          getOptionLabel={(option) =>
                            option?.level1?.Supplier || ""
                          }
                          onChange={(event, newValue) => {
                            if (newValue?.level1?.SupplierId) {
                              setFilters((filters) => ({
                                ...filters,
                                supplier: newValue,
                              }));
                              _getPurchaseOrders(
                                newValue?.level1?.SupplierId,
                                ""
                              );
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={"Select Supplier"}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {vendorLoading ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                      </GridItem>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        style={{ marginTop: "10px", marginBottom: "10px" }}
                      >
                        <Autocomplete
                          id="filter-demo"
                          options={_PurchaseOrderList || []}
                          value={formState.selectedPurchaseOrder || ""}
                          getOptionLabel={(option) => option?.OrderNumber || ""}
                          onInputChange={(event, newInputValue) => {
                            setFormState((formState) => ({
                              ...formState,
                              poInputValue: newInputValue,
                            }));
                            if (!formState.selectedVendor) {
                              _getPurchaseOrders(null, newInputValue);
                            }
                          }}
                          onChange={(event, newValue) => {
                            setFormState((formState) => ({
                              ...formState,
                              selectedPurchaseOrder: newValue,
                            }));
                            if (newValue?.OrderNumber) {
                              setFilters((filters) => ({
                                ...filters,
                                po: newValue?.OrderNumber,
                              }));
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={"Search By PO"}
                              placeholder={"Search"}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {poLoading ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          {/* <GridItem xs={12}>
            <Card>
              <CardHeader color='info' icon>
                <CardIcon color='info'>
                  <h4 className={classes.cardTitleText}>Search Invoice</h4>
                </CardIcon>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={10}
                    lg={10}
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                  >
                    <Autocomplete
                      id='documentname'
                      value={fileName}
                      onChange={(event, newValue) => {
                        setFileName(newValue)
                        if (newValue !== null) {
                          setDisabledSearch(false)
                        } else {
                          setDisabledSearch(true)
                        }
                      }}
                      open={open}
                      onOpen={() => {
                        setOpen(true)
                      }}
                      onClose={() => {
                        setOpen(false)
                      }}
                      getOptionSelected={(option, value) =>
                        option?.name === value?.name
                      }
                      getOptionLabel={option => option?.name}
                      options={options}
                      loading={loading}
                      inputValue={inputValue}
                      onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue)
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label='Invoice Number'
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loading ? (
                                  <CircularProgress color='inherit' size={20} />
                                ) : null}

                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            )
                          }}
                        />
                      )}
                    />
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={2}
                    lg={2}
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                  >
                    <Button
                      color='danger'
                      className={classes.registerButton}
                      type='submit'
                      size='sm'
                      round
                      block
                      onClick={getFile}
                      disabled={disabledSearch}
                    >
                      {isSearching ? (
                        <CircularProgress color='inherit' size={20} />
                      ) : (
                        <SearchIcon />
                      )}
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem> */}
        </GridContainer>
      </Animated>
      <Animated
        animationIn="bounceInUp"
        animationOut="bounceOutDown"
        animationInDuration={1000}
        animationOutDuration={1000}
        isVisible={animateFileInfo}
      >
        <GridContainer>
          <GridItem xs={12}>
            <GridContainer>
              <GridItem lg={12} md={12} sm={12} xs={12}>
                <Card>
                  <CardHeader color="danger" icon>
                    <CardIcon color="danger">
                      <h4 className={classes.cardTitleText}>Invoice Info</h4>
                    </CardIcon>
                  </CardHeader>
                  <CardBody>
                    {fileData.length > 0 ? (
                      <>
                        <Table
                          hover
                          tableHeaderColor="info"
                          tableShopping={true}
                          tableHead={[
                            "Invoice Number",
                            "Submit Date",
                            "Status",
                            "Due Date",
                            isAr ? "Customer Name" : "Vendor Name",
                            "Amount",
                            "Version",
                            "Action",
                          ]}
                          tableData={fileData?.map((file, index) => {
                            var currentStatus = currentTracking(
                              file?.trackingStatus
                            );
                            // let isSubmitedByVendor =
                            //   !file?.initWorkFLow && !isVendor && file?.markedAs == 'unread'
                            let isCorrectionRequiredInWorkflow =
                              // !isVendor && file?.workFlowStatus == 'correctionRequired'
                              file?.workFlowStatus == "correctionRequired";
                            return [
                              file?.originalInvoiceId,
                              formatDateTime(file?.invoiceDate),

                              file?.trackingStatus?.received?.status ==
                              "inProgress" ? (
                                <Tooltip title="INCOMPLETE">
                                  <Chip
                                    variant="outlined"
                                    size="small"
                                    // avatar={<Avatar>M</Avatar>}
                                    label="INCOMPLETE"
                                    clickable
                                    onClick={() =>
                                      _trackingStatus(file?.trackingStatus)
                                    }
                                    style={{
                                      border: "orange 1px solid",
                                      color: "orange",
                                    }}
                                  />
                                </Tooltip>
                              ) : // if Inoice is Not Draft
                              file?.trackingStatus?.paid?.status ==
                                "partial" ? (
                                <Tooltip title="PARTIALLY PAID">
                                  <Chip
                                    variant="outlined"
                                    size="small"
                                    // avatar={<Avatar>M</Avatar>}
                                    label="PARTIALLY PAID"
                                    clickable
                                    onClick={() =>
                                      _trackingStatus(file?.trackingStatus)
                                    }
                                    style={{
                                      border: "lightgreen 1px solid",
                                      color: "lightgreen",
                                    }}
                                  />
                                </Tooltip>
                              ) : file?.trackingStatus?.paid?.status ==
                                "completed" ? (
                                <Tooltip title="FULLY PAID">
                                  <Chip
                                    variant="outlined"
                                    size="small"
                                    // avatar={<Avatar>M</Avatar>}
                                    label="FULLY PAID"
                                    clickable
                                    onClick={() =>
                                      _trackingStatus(file?.trackingStatus)
                                    }
                                    style={{
                                      border: "green 1px solid",
                                      color: "green",
                                    }}
                                  />
                                </Tooltip>
                              ) : file?.trackingStatus?.paid?.status ==
                                "Canceled" ? (
                                <Tooltip title="CANCELED">
                                  <Chip
                                    variant="outlined"
                                    size="small"
                                    // avatar={<Avatar>M</Avatar>}
                                    label="CANCELED"
                                    clickable
                                    onClick={() =>
                                      _trackingStatus(file?.trackingStatus)
                                    }
                                    color="secondary"
                                  />
                                </Tooltip>
                              ) : currentStatus.status == "readyToPay" ? (
                                <Tooltip title="READY TO PAY">
                                  <Chip
                                    variant="outlined"
                                    size="small"
                                    // avatar={<Avatar>M</Avatar>}
                                    label="READY TO PAY"
                                    clickable
                                    onClick={() =>
                                      _trackingStatus(file?.trackingStatus)
                                    }
                                    style={{
                                      border: "orange 1px solid",
                                      color: "orange",
                                    }}
                                  />
                                </Tooltip>
                              ) : file?.trackingStatus?.paymentInProcess
                                  ?.status == "completed" ? (
                                <Tooltip title="APPROVED AND EXPORTED">
                                  <Chip
                                    variant="outlined"
                                    size="small"
                                    // avatar={<Avatar>M</Avatar>}
                                    label="APPROVED AND EXPORTED"
                                    clickable
                                    onClick={() =>
                                      _trackingStatus(file?.trackingStatus)
                                    }
                                    style={{
                                      border: "green 1px solid",
                                      color: "green",
                                    }}
                                  />
                                </Tooltip>
                              ) : file?.workFlowStatus ==
                                  "correctionRequired" &&
                                file?.markedAs !== "rejected" ? (
                                <Tooltip title="Correction Required">
                                  <Chip
                                    variant="outlined"
                                    size="small"
                                    // avatar={<Avatar>M</Avatar>}
                                    label="CORRECTION REQUIRED"
                                    clickable
                                    onClick={() =>
                                      _trackingStatus(file?.trackingStatus)
                                    }
                                    style={{
                                      border: "orange 1px solid",
                                      color: "orange",
                                    }}
                                  />
                                </Tooltip>
                              ) : file?.trackingStatus?.underApprove?.status ==
                                "inProgress" ? (
                                <Tooltip title="PENDING">
                                  <Chip
                                    variant="outlined"
                                    size="small"
                                    // avatar={<Avatar>M</Avatar>}
                                    label="PENDING"
                                    clickable
                                    onClick={() =>
                                      _trackingStatus(file?.trackingStatus)
                                    }
                                    color="primary"
                                  />
                                </Tooltip>
                              ) : file?.trackingStatus?.underApprove?.status ==
                                  "completed" &&
                                file?.trackingStatus?.paymentInProcess
                                  ?.status !== "completed" ? (
                                <Tooltip title="APPROVED">
                                  <Chip
                                    variant="outlined"
                                    size="small"
                                    // avatar={<Avatar>M</Avatar>}
                                    label="APPROVED"
                                    clickable
                                    onClick={() =>
                                      _trackingStatus(file?.trackingStatus)
                                    }
                                    style={{
                                      border: "green 1px solid",
                                      color: "green",
                                    }}
                                  />
                                </Tooltip>
                              ) : file?.trackingStatus?.underReview?.status ==
                                "inProgress" ? (
                                <Tooltip title="PENDING">
                                  <Chip
                                    variant="outlined"
                                    size="small"
                                    // avatar={<Avatar>M</Avatar>}
                                    label="PENDING"
                                    clickable
                                    onClick={() =>
                                      _trackingStatus(file?.trackingStatus)
                                    }
                                    color="primary"
                                  />
                                </Tooltip>
                              ) : file?.trackingStatus?.initialReview?.status ==
                                "inProgress" ? (
                                <Tooltip title="PENDING">
                                  <Chip
                                    variant="outlined"
                                    size="small"
                                    // avatar={<Avatar>M</Avatar>}
                                    label="PENDING"
                                    clickable
                                    onClick={() =>
                                      _trackingStatus(file?.trackingStatus)
                                    }
                                    color="primary"
                                  />
                                </Tooltip>
                              ) : currentStatus?.status == "rejected" ? (
                                <Tooltip title="REJECTED">
                                  <Chip
                                    variant="outlined"
                                    size="small"
                                    // avatar={<Avatar>M</Avatar>}
                                    label="REJECTED"
                                    clickable
                                    onClick={() =>
                                      _trackingStatus(file?.trackingStatus)
                                    }
                                    color="secondary"
                                  />
                                </Tooltip>
                              ) : currentStatus?.status == "Canceled" ? (
                                <Tooltip title="Canceled">
                                  <Chip
                                    variant="outlined"
                                    size="small"
                                    // avatar={<Avatar>M</Avatar>}
                                    label="Canceled"
                                    clickable
                                    onClick={() =>
                                      _trackingStatus(file?.trackingStatus)
                                    }
                                    color="secondary"
                                  />
                                </Tooltip>
                              ) : (currentStatus?.status ==
                                  "correctionRequired" &&
                                  currentStatus?.val == 1) ||
                                isCorrectionRequiredInWorkflow ? (
                                <Tooltip title="SENT FOR CORRECTION">
                                  <Chip
                                    variant="outlined"
                                    size="small"
                                    // avatar={<Avatar>M</Avatar>}
                                    label="SENT FOR CORRECTION"
                                    onClick={() =>
                                      _trackingStatus(file?.trackingStatus)
                                    }
                                    clickable
                                    style={{
                                      border: "orange 1px solid",
                                      color: "orange",
                                    }}
                                  />
                                </Tooltip>
                              ) : (
                                <Tooltip title="PENDING">
                                  <Chip
                                    variant="outlined"
                                    size="small"
                                    // avatar={<Avatar>M</Avatar>}
                                    label="PENDING"
                                    clickable
                                    onClick={() =>
                                      _trackingStatus(file?.trackingStatus)
                                    }
                                    color="primary"
                                  />
                                </Tooltip>
                              ),
                              formatDate(file.dueDate),
                              isAr ? file.clientName : file.vendorName,
                              `${file.FC_currency.Code}${addZeroes(
                                file.netAmt
                              )}`,
                              file.version,
                              <Button
                                round
                                color={Selected == index ? "danger" : "info"}
                                className="Edit"
                                id={"selectFile" + index}
                                name={"selectFile" + index}
                                onClick={() => selectFile(file, index)}
                                size="sm"
                              >
                                {/* <SelectAllIcon /> */}
                                {Selected == index ? "Selected" : "select"}
                              </Button>,
                            ];
                          })}
                        />
                        {filterStatus !== "" ? (
                          <TablePagination
                            component="div"
                            count={totalCount}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </CardBody>
                </Card>
              </GridItem>
              {file !== null ? (
                <GridItem lg={12} md={12} sm={12} xs={12}>
                  <Card>
                    <CardHeader color="info" icon>
                      <CardIcon color="info">
                        <h4 className={classes.cardTitleText}>
                          360&#176; View
                        </h4>
                      </CardIcon>
                    </CardHeader>
                    <CardBody>
                      <GridContainer justify="center">
                        <GridItem
                          xs={12}
                          sm={12}
                          md={12}
                          className={classes.center}
                        >
                          {file !== null ? (
                            <FileAdvanceView fileData={file} />
                          ) : file == null ? (
                            "Please select a file"
                          ) : (
                            "No Data Available"
                          )}
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </GridItem>
              ) : (
                ""
              )}
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Animated>
    </div>
  );
}
